import { navTree } from './navTree';

type TreeItem = {
  title: string;
  slug: string;
};

type Tree = {
  main: TreeItem[];
  footer: TreeItem[];
};

const useSiteNavTree = (): Tree => {
  return navTree;
};

export default useSiteNavTree;
