/** @jsx jsx */
import React from 'react';
import { jsx, Box } from 'theme-ui';

type Props = {
  sx?: any; // Find proper sx type
};

import LogoImage from './logo.png';
import { Link } from 'gatsby';

const Logo: React.FC<Props> = () => {
  return (
    <Link to="/">
      <img
        src={LogoImage}
        sx={{
          height: `32px`,
          mr: 2,
        }}
      />
      <span
        sx={{
          position: 'absolute',
          width: 1,
          height: 1,
          overflow: 'hidden',
          top: -9999,
        }}
      >
        Home
      </span>
    </Link>
  );
};

export default Logo;
