/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';

import SEO from './seo';
import Header from './header';
import Footer from './footer';
type LayoutProps = { children: React.ReactNode };

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Fragment>
    <SEO />
    <Header />
    <Styled.root as="main">{children}</Styled.root>
  </Fragment>
);

export default Layout;
