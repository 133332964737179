import React from 'react';

import Cover from 'react-video-cover';

import { withPrefix } from 'gatsby';

const videoOptions = {
  src: withPrefix('/assets/intro.mp4'),
  autoPlay: true,
  muted: true,
  loop: true,
  playsInline: true,
  disableRemotePlayback: true,
};

const VideoCover = ({ videoSrcURL, videoTitle, ...props }) => (
  <Cover {...props} videoOptions={videoOptions} remeasureOnWindowResize />
);
export default VideoCover;
