/** @jsx jsx */
import { jsx, Styled, Flex } from 'theme-ui';
import { Link } from 'gatsby';

type Props = {
  nav: {
    title: string;
    slug: string;
  }[];
};

const Navigation = ({ nav }: Props) => {
  if (!nav) {
    return null;
  }

  return (
    <Flex as="nav" sx={{ display: ['none', 'block'] }}>
      {nav.map((item) => (
        <Link
          key={item.slug}
          activeClassName="active"
          to={item.slug}
          sx={{ variant: 'layout.header.navLink' }}
        >
          {item.title}
        </Link>
      ))}
    </Flex>
  );
};

export default Navigation;
