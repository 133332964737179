import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import useSiteMetadata from '../hooks/use-site-metadata';

type Props = {
  title?: string;
  description?: string;
  pathname?: string;
  image?: string;
  children?: React.ReactNode;
};

const SEO = ({ title, description, pathname, image, children }: Props) => {
  const site = useSiteMetadata();

  const {
    siteTitle,
    siteUrl,
    siteDescription,
    siteLanguage,
    siteImage,
    author,
  } = site;

  const seo = {
    title: title,
    description: description || siteDescription,
    url: `${siteUrl}${pathname || ``}`,
    image: `${siteUrl}${image || siteImage}`,
  };

  return (
    <Helmet title={title} titleTemplate={`${siteTitle} | %s`}>
      <html lang={siteLanguage} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content={seo.description} />
      <meta name="twitter:creator" content={author} />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={withPrefix(`/favicon-32x32.png`)}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={withPrefix(`/favicon-16x16.png`)}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={withPrefix(`/apple-touch-icon.png`)}
      />
      <meta name="gatsby-theme" content="@denu5/gatsby-theme-squaresby" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {children}
    </Helmet>
  );
};

export default SEO;
