import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Obfuscate = makeShortcode("Obfuscate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Grid gap={[3]} columns={[1, 2]} sx={{
      alignItems: 'center'
    }} mdxType="Grid">
      <Box mdxType="Box">
        <h2>{`Fahrschule 2drive`}</h2>
        <p>{`Andreas Schmid`}<br parentName="p"></br>{`
`}{`Staatlich geprüfter Fahrlehrer`}</p>
        <p>{`Rotachstrasse 34`}<br parentName="p"></br>{`
`}{`8003 Zürich`}</p>
      </Box>
      <Box mdxType="Box">
        <p>{`Telefon: `}<Obfuscate style={{
            display: 'inline-block'
          }} tel="079 336 36 00" mdxType="Obfuscate" /><br />
E&#8209;Mail: <Obfuscate style={{
            display: 'inline-block'
          }} email="fahrschule@2drive.ch" mdxType="Obfuscate" /></p>
      </Box>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      