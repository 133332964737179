/** @jsx jsx */
import { jsx } from 'theme-ui';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../layout/layout';
import SEO from '../layout/seo';

import * as MdxComponents from '../utils/mdx-components';
import Footer from '../layout/footer';

type Props = {
  page: {
    title: string;
    slug: string;
    description: string;
    body: string;
    // TODO - ShowFooter boolean ?
  };
};

const Page = ({ page }: Props) => (
  <Layout>
    <SEO title={page.title} description={page.description} />
    <MDXProvider components={MdxComponents}>
      <MDXRenderer data={page}>{page.body}</MDXRenderer>
      <Footer />
    </MDXProvider>
  </Layout>
);

export default Page;
