/** @jsx jsx */
import { jsx, Button as ButtonTui } from 'theme-ui';
import { Link } from 'gatsby';

type Props = {
  to?: string;
  sx?: any;
  children: string;
  variant: string;
};

// Need this custom Button because of: https://theme-ui.com/sx-prop#using-the-sx-prop-in-mdx

const Button: React.FC<Props> = ({
  to,
  sx,
  variant = 'primary',
  children,
  ...props
}) => {
  sx = {
    variant: `buttons.${variant}`,
  };

  if (to) {
    return (
      <Link to={to} sx={sx} {...props}>
        {children}
      </Link>
    );
  } else {
    return (
      <ButtonTui sx={sx} {...props}>
        {children}
      </ButtonTui>
    );
  }
};

export default Button;
