/** @jsx jsx */
import { jsx } from 'theme-ui';

import Obfuscate from 'react-obfuscate';

type Props = {
  to?: string;
  sx?: any;
  children: string;
  variant: string;
};

// Need to wrap this because of: https://theme-ui.com/sx-prop#using-the-sx-prop-in-mdx

const Button: React.FC<Props> = ({ ...props }) => {
  props.sx = { ...{ variant: 'styles.a' }, ...{ ...props.sx } };
  return <Obfuscate {...props} />;
};

export default Button;
