/** @jsx jsx */
import { jsx } from 'theme-ui';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './swiper-fix.css';

interface Props {
  children: React.ReactNode;
  items: number | number[];
  sx: any;
}

// TODO take breakpoints from theme-ui conifg

const responsive = {
  tablet: {
    breakpoint: { max: 100000, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Swiper: React.FC<Props> = ({ children, sx, items = 1 }) => {
  return (
    <Carousel responsive={responsive} sx={sx}>
      {children}
    </Carousel>
  );
};

export default Swiper;
