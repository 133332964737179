/** @jsx jsx */
import { jsx, useColorMode, SxStyleProp } from 'theme-ui';
import { Link } from 'gatsby';
import { Button, Container, MenuButton, Flex } from '@theme-ui/components';
import useSiteMetadata from '../hooks/use-site-metadata';
import Navigation from './navigation';

import Logo from '../components/logo';

// @ts-ignore
import { Cross as Hamburger } from 'hamburger-react';

import useSiteNavTree from '../hooks/use-site-nav-tree';
import { useState } from 'react';

// TODO could move to variants ?
const navStyles: SxStyleProp[] = [
  {
    opacity: 0,
    visibility: 'hidden',
  },
  {
    opacity: 1,
    visibility: 'visible',
  },
];

const Header = () => {
  const { main } = useSiteNavTree();

  const [isOpen, setOpen] = useState(false);

  const toggleNavStyle: SxStyleProp = isOpen ? navStyles[1] : navStyles[0];

  return (
    <header
      sx={{
        variant: 'layout.header',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: ['6vw', '4vw'],
        }}
      >
        <Logo />

        <Navigation nav={main} />

        <Flex sx={{ display: ['block', 'none'] }}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </Flex>
        <Flex
          sx={{
            bg: 'white',
            position: 'fixed',
            top: '6vh',
            right: 0,
            bottom: 0,
            left: 0,
            flexDirection: 'column',
            willChange: 'opacity, visibility',
            userSelect: 'none',
            pointerEvents: 'auto',
            transition:
              'visibility 600ms cubic-bezier(.4,0,.2,1),opacity 400ms cubic-bezier(.4,0,.2,1)',
            ...toggleNavStyle,
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              willChange: 'transform',
              transition:
                'transform 600ms cubic-bezier(.4,0,.2,1),visibility 600ms cubic-bezier(.4,0,.2,1),opacity 400ms cubic-bezier(.4,0,.2,1)',
              transform: isOpen ? 'translatey(20px)' : 'translatey(0)',
            }}
          >
            {main.map((item) => (
              <Link
                key={item.slug}
                activeClassName="active"
                to={item.slug}
                sx={{ p: 3, fontSize: 3, variant: 'layout.header.navLink' }}
              >
                {item.title}
              </Link>
            ))}
          </Flex>
        </Flex>

        {/* <Button
          ml={4}
          onClick={(e) => {
            setColorMode(colorMode === 'default' ? 'dark' : 'default');
          }}
        >
          Toggle {colorMode === 'default' ? 'Dark' : 'Light'}
        </Button> */}
      </Container>
    </header>
  );
};

export default Header;
