/** @jsx jsx */
import React from 'react';
import { jsx, Box } from 'theme-ui';

type Props = {
  sx: any; // Find proper sx type
};

const Block: React.FC<Props> = ({ children, sx }) => {
  if (!children) return null;

  return (
    <Box
      sx={{
        textAlign: 'center',
        maxWidth: [`none`, `80%`, `80%`, `50%`],
        mx: `auto`,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Block;
