/** @jsx jsx */
import React from 'react';
import { jsx, Box } from 'theme-ui';

type Props = {
  sx?: any; // Find proper sx type
};

const Spacer: React.FC<Props> = () => {
  return <Box sx={{ height: '2vh' }}></Box>;
};

export default Spacer;
