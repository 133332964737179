/** @jsx jsx */
import React from 'react';
import {
  jsx,
  Box,
  Container,
  ThemeProvider,
  useThemeUI,
  Heading,
} from 'theme-ui';

import { SxStyleProp } from 'theme-ui';

import theme from '../gatsby-plugin-theme-ui';

// React.ReactNode
export const Cover: React.FC<any> = ({ children }) => {
  if (!children) return null;
  return (
    <div
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        // '::after': {
        //   content: '""',
        //   width: '100%',
        //   height: '100%',
        //   position: 'absolute',
        //   top: 0,
        //   left: 0,
        //   zIndex: 0,
        //   bg: '#000',
        //   opacity: 0.3,
        // },
        '> .gatsby-image-wrapper': {
          height: '100%',
        },
      }}
    >
      {children}
    </div>
  );
};

const Horizontal = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const Vertical = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

type Props = {
  sx?: SxStyleProp;
  sectionHeight?: 's' | 'm' | 'l' | number;
  contentWidth?: 's' | 'm' | 'l';
  hAlign?: 'left' | 'center' | 'right';
  vAlign?: 'top' | 'bottom' | 'center';
  sectionTheme?: string;
  as: 'section' | React.ElementType;
  cover?: React.ReactNode;
};

const Section: React.FC<Props> = ({
  children,
  cover,
  sectionHeight = 's',
  contentWidth = 'l',
  hAlign = 'center',
  vAlign = 'center',
  sectionTheme = 'white',
  as = 'section',
  ...props
}) => {
  let colors = {};

  try {
    if (sectionTheme) {
      colors = { colors: { ...theme.colors.modes[sectionTheme] } };
    }
  } catch (err) {
    // todo use gs reporter
    console.error('invalid color mode');
  }

  // Todo throw error if theme not available in config and fallback

  let customHeight: SxStyleProp = {};
  if (typeof sectionHeight === 'number') {
    // TOOD check 10-100 range like squarespace?
    customHeight = {
      minHeight: sectionHeight + 'vh',
      py: sectionHeight * 0.1 + 'vmax',
    };
  }

  return (
    <ThemeProvider theme={colors}>
      <Box
        as={as}
        {...props}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          bg: 'background',
        }}
      >
        <Cover>{cover}</Cover>
        <Container
          sx={{
            display: 'flex',
            justifyContent: ['flex-start', Horizontal[hAlign]],
            alignItems: [Vertical[vAlign]],
            variant: `section.${sectionHeight}`,
            ...customHeight,
            px: ['6vw', '4vw'],
          }}
        >
          <Box
            sx={{
              position: 'relative',
              zIndex: 1,
              variant: `section.content.${contentWidth}`,
              m: 0,
            }}
          >
            {children && children}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Section;
