import { graphql } from 'gatsby';
import PageComponent from '../components/page';

export default PageComponent;

export const query = graphql`
  query($slug: String!) {
    page(slug: { eq: $slug }) {
      slug
      title
      description
      body
      excerpt
      timeToRead
      cover {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 600, cropFocus: CENTER, fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
