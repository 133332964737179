import React from 'react';
import Page from '../../../components/page';

type Props = {
  data: {
    page: any;
    [key: string]: any;
  };
};

export default ({ data, pageContext }: Props) => {
  const { page } = data;
  return <Page page={page} />;
};
