// https://github.com/system-ui/theme-ui/blob/master/packages/preset-base/src/index.ts
// https://github.com/arshad/gatsby-themes/blob/master/themes/gatsby-theme-flex/src/gatsby-plugin-theme-ui/base.js

import { darken, invert, lighten } from '@theme-ui/color';

const buttonStyles = {
  appearance: 'none',
  display: 'inline-flex',
  textAlign: 'center',
  lineHeight: 'inherit',
  textDecoration: 'none',
  fontSize: 1,
  px: 4,
  py: 2,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: 'forms',
  cursor: 'pointer',
  fontFamily: 'body',
};

export const colors = {
  modes: {
    dark: {
      text: '#fff',
      background: '#3e3d3d',
      primary: '#F44336',
      secondary: '#82aaff',
      muted: '#191919',
      highlight: '#29112c',
      gray: '#999',
      accent: '#c0f',
    },
    light: {
      text: '#000',
      background: '#f1f1f1',
      primary: '#F44336',
      secondary: '#111',
      muted: '#f4f4f4',
      highlight: '#efeffe',
      gray: '#777',
      accent: '#33e',
    },
    white: {
      text: '#000',
      background: 'white',
      primary: '#F44336',
      secondary: '#82aaff',
      muted: '#191919',
      highlight: '#29112c',
      gray: '#999',
      accent: '#c0f',
    },
  },
};

export default {
  useCustomProperties: true,
  breakpoints: ['720px'],
  sizes: {
    container: '1500px',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2rem',
    '2.25rem', // h2
    '4rem', // h1
    '6rem',
    '8rem',
  ],
  fonts: {
    body: '"Comic Sans MS", "Helvetica Neue", sans-serif',
    heading: '"Comic Sans MS", "Helvetica Neue", sans-serif',
  },
  fontWeights: {
    body: 500,
    heading: 500,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.125,
  },
  colors,
  initialColorModeName: 'light',
  text: {
    caps: {
      textTransform: 'uppercase',
      fontFamily: 'heading',
      letterSpacing: '0.1rem',
      color: 'text',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      letterSpacing: '0.1rem',
      m: 0,
      mb: 1,
      color: 'text',
    },
    paragraph: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      m: 0,
      mb: '1rem',
      color: 'text',
    },
    h1: {
      variant: 'text.heading',
      fontSize: [6],
    },
    h2: {
      variant: 'text.heading',
      fontSize: [5],
    },
    h3: {
      variant: 'text.heading',
      fontSize: [4],
    },
    h4: {
      variant: 'text.heading',
      fontSize: [3],
    },
    p1: {
      variant: 'text.paragraph',
      fontSize: [2],
    },
    p2: {
      variant: 'text.paragraph',
      fontSize: [1],
    },
    p3: {
      variant: 'text.paragraph',
      fontSize: [0],
    },
  },

  radii: { forms: 0 },
  shadows: {
    default: `0 1px 3px rgba(0, 0, 0, .12), 0 1px 3px rgba(0, 0, 0, .24)`,
    md: `0 3px 6px rgba(0, 0, 0, .15), 0 2px 4px rgba(0, 0, 0, .12)`,
    lg: `0 10px 20px rgba(0, 0, 0, .15), 0 3px 5px rgba(0, 0, 0, .1)`,
    xl: `0 15px 25px rgba(0, 0, 0, .15), 0 5px 10px rgba(0, 0, 0, .05)`,
    xxl: `0 20px 40px rgba(0, 0, 0, .2)`,
    none: `none`,
  },
  // Styling within Mdx only, not outside
  styles: {
    root: {
      mt: ['8vh', '6vh'],
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    p: {
      variant: 'text.p2',
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    h1: {
      variant: 'text.heading',
      fontSize: 6,
      mt: 2,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 5,
      mt: 2,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 4,
      mt: 3,
    },
    h4: {
      variant: 'text.heading',
      fontSize: 3,
    },
    code: {},
    pre: {},
    hr: {
      bg: 'muted',
      border: 0,
      height: '1px',
      m: 3,
    },
    ins: {
      color: 'primary',
    },
    table: {
      maxWidth: ['none'],
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      th: {
        pb: [2],
        textAlign: 'left',
        borderBottomStyle: 'solid',
        borderColor: 'secondary',
        borderWidth: 1,
      },
      td: {
        pt: [2],
        pb: [2],
        textAlign: 'left',
        borderBottomStyle: 'solid',
        borderColor: 'secondary',
        borderWidth: 1,
      },
    },
  },
  buttons: {
    primary: {
      ...buttonStyles,
      bg: 'primary',
      borderColor: 'primary',
      color: 'white',
      '&:hover': {
        bg: darken('primary', 0.2),
        borderColor: darken('primary', 0.2),
      },
    },
    secondary: {
      ...buttonStyles,
      bg: 'secondary',
      borderColor: 'secondary',
      color: invert('text'),
      '&:hover': {
        bg: darken('secondary', 0.2),
        borderColor: darken('secondary', 0.2),
      },
    },
    link: {
      ...buttonStyles,
      color: 'primary',
      px: 0,
      py: 0,
      bg: 'transparent',
      borderColor: 'transparent',
    },
  },
  forms: {
    input: {
      borderRadius: 'forms',
    },
    select: {
      borderRadius: 'forms',
    },
    textarea: {
      borderRadius: 'forms',
    },
  },
  section: {
    s: {
      minHeight: '33vh',
      py: '3.3vmax',
    },
    m: {
      minHeight: '66vh',
      py: '6.6vmax',
    },
    l: {
      minHeight: '100vh',
      py: '10vmax',
    },
    content: {
      s: {
        width: ['100%', '50%'],
      },
      m: {
        width: ['100%', '75%'],
      },
      l: {
        width: '100%',
      },
    },
    spacing: {
      margin: '4vw',
      maxWidth: '1500px',
    },
  },
  layout: {
    header: {
      bg: 'white',
      position: 'fixed',
      zIndex: 2,
      width: '100%',
      top: 0,
      left: 0,
      minHeight: ['8vh', '6vh'],
      display: 'flex',
      alignItems: 'center',
      navLink: {
        // bg: 'red',
        variant: 'text.p2',
        color: lighten('text', 0.6),
        p: 3,
        textDecoration: 'none',
        '&.active': {
          color: 'text',
        },
        ':hover': {
          color: lighten('text', 0.3),
        },
        ':not(:first-of-type)': {
          ml: 3,
        },
      },
    },
  },
};

// export default merge(base, theme);
