/** @jsx jsx */
import { jsx } from 'theme-ui';
import useSiteMetadata from '../hooks/use-site-metadata';
import { Link } from 'gatsby';
import useSiteNavTree from '../hooks/use-site-nav-tree';
import Section from '../components/section';

// @ts-ignore
import FooterLeft from '../texts/footer-left';

const Footer = () => {
  const { siteTitle } = useSiteMetadata();
  const nav = useSiteNavTree();

  return (
    <Section sectionTheme="dark" as="footer">
      <FooterLeft />

      {/* <div sx={{ mx: 'auto' }} />
      <div sx={{ p: 2 }}>
        &copy; {new Date().getFullYear()} by {siteTitle} - All rights reserved.
      </div> */}
    </Section>
  );
};

export default Footer;
