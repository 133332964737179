/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Label, Input, Select, Textarea, Button } from '@theme-ui/components';

const ContactForm = () => {
  return (
    <form
      name="kontakt"
      method="post"
      netlifyhoneypot="bot-field"
      data-netlify="true"
      action="kontakt/danke"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="kontakt" />
      <Label htmlFor="vorname">Vorname</Label>
      <Input name="vorname" mb={3} required />
      <Label htmlFor="nachname">Nachname</Label>
      <Input name="nachname" mb={3} required />
      <Label htmlFor="email">Email</Label>
      <Input type="email" name="email" mb={3} required />
      <Label htmlFor="telefon">Telefon</Label>
      <Input type="tel" name="telefon" mb={3} required />
      <Label htmlFor="interesse">Ich interessiere mich für</Label>
      <Select name="interesse" mb={3} required>
        <option>Fahrstunden Automat</option>
        <option>Fahrstunden Handschaltung</option>
        <option>Sonstige Unterstützung</option>
      </Select>
      <Label htmlFor="nachricht">Nachricht</Label>
      <Textarea name="nachricht" rows="6" mb={3} required />
      <Button type="submit">Absenden</Button>
    </form>
  );
};

export default ContactForm;
