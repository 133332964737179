import { merge } from 'theme-ui';

import base from '@denu5/gatsby-theme-squaresby/src/gatsby-plugin-theme-ui';

const theme = {
  fonts: {
    body: '"Source Sans Pro", "Helvetica Neue", sans-serif',
    heading: '"Source Sans Pro", "Helvetica Neue", sans-serif',
  },
  colors: {},
};

export default merge(base, theme);
